:root {
  --operator-color: #5a42ec;
  --double-quotes-color: #be3b93;
  --grey-color: rgb(209, 213, 220);
}

* {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}
body {
  margin: 0;
  overflow: hidden;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* body::-webkit-scrollbar {
  display: none;
} */

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: #ffffff;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #585858;
}
::-webkit-scrollbar-thumb:hover {
  cursor: grab;
  background: #857ef5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.x.axis > .tick > text,
.y.axis > .tick > text {
  fill: #5c5e60;
  font-size: 0.75rem;
  /* text-transform: capitalize; */
}
.x.axis > .domain,
.y.axis > .domain {
  /* stroke: #d9dbde; */
  stroke: #c1c7cd;
}

.y-subtitle {
  fill: #000 !important;
  font-weight: 600 !important;
  font-size: 12px !important;
}

.x.axis > .tick > line:first-child,
.y.axis > .tick > line:first-child {
  display: none;
}

.calendarElement {
  z-index: 500;
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.scroll-hidden::-webkit-scrollbar {
  display: none;
}

.draggable {
  display: flex;
  align-items: center;
}

.draggable:hover div {
  opacity: 1 !important;
}

.add-html-data .ql-container {
  height: 70vh !important;
}

.customSunEditor .sun-editor {
  border: none !important;
}

.customSunEditor .sun-editor .se-wrapper {
  z-index: 0 !important;
}

.customSunEditor .sun-editor .se-container .se-wrapper .se-wrapper-inner {
  height: 100% !important;
}

.customDescription .se-read-only {
  padding: 0 !important;
  font-family: Inter;
  margin: 0 !important;
  color: #030303;
  font-size: 0.936rem;
  line-height: 1.1875rem;
  font-weight: 500;
  letter-spacing: -0.015rem;
}

/* Need to customize like this to override editor styles */
.customDescription .se-read-only p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 6; */
  text-overflow: ellipsis;
}

.customDescription .sun-editor .se-container .se-resizing-bar {
  display: none !important;
  /* background-color: red; */
}

.customSunEditor .sun-editor .se-container .se-wrapper .se-wrapper-inner {
  font-family: 'Inter', sans-serif !important;
  padding: 0;
}

.sun-editor-editable * {
  font-family: 'Inter', sans-serif !important;
  margin: 0 !important;
}

.sun-editor .se-resizing-bar {
  display: none !important;
}

.customDescription p {
  margin: 0;
}
.sun-editor .se-dialog .se-dialog-inner div.se-dialog-form-footer {
  display: none !important;
}

.sun-editor
  .se-dialog
  .se-dialog-inner
  .se-dialog-form
  .se-dialog-form-files
  button._se_bookmark_button {
  display: none !important;
}

/* .treemap-rect {
  box-shadow: 0px 12px 24px 0px #0e62fe66;
} */

.selected {
  transition: all 400ms ease;
  opacity: 1 !important;
}

.unselected {
  transition: all 400ms ease;
  opacity: 0.2 !important;
}

.graph-widget {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.override-padding {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.treemap-rect {
  box-shadow: 0px 12px 24px 0px #0e62fe66;
}

.selected {
  transition: all 400ms ease;
  opacity: 1 !important;
}

.unselected {
  transition: all 400ms ease;
  opacity: 0.2 !important;
}

.graph-widget {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.override-padding {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.range-slider .range-slider__thumb[data-disabled] {
  height: 0.125rem !important;
}

.markdown-styles ul,
ol {
  padding-left: 1.1rem;
  line-height: 160%;
  font-size: 0.8125rem;
  letter-spacing: unset;
}

.loading {
  display: flex;
  align-items: center;
  color: #5f39f8;
  justify-content: center;
  height: 100%;
}

.loading svg {
  margin-left: 2px;
  margin-top: 4px;
}

.infinite-scroll-component__outerdiv {
  height: 100%;
}

.treemap-rect {
  transition: opacity 0.3s ease;
}

.faded {
  opacity: 0.3;
}

.concentric-pie-chart {
  width: 100% !important;
  height: 100% !important;
}
